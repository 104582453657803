"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItsTaskMock = void 0;
const its_task_model_1 = require("./its-task.model");
class ItsTaskMock extends its_task_model_1.ItsTask {
    constructor(data) {
        const seedData = Object.assign({ taskId: 'CK09YM', taskName: 'MPRS CERT' }, data);
        super(seedData);
    }
}
exports.ItsTaskMock = ItsTaskMock;
