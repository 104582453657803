"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItsTaskGroup = void 0;
const base_model_1 = require("../base-model");
const its_task_group_schema_1 = require("./its-task-group.schema");
class ItsTaskGroup extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
        if (!this.tasks) {
            this.tasks = [];
        }
    }
    getDefaults() {
        return {};
    }
    getSchema() {
        return its_task_group_schema_1.itsTaskGroupSchema;
    }
}
exports.ItsTaskGroup = ItsTaskGroup;
