"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItsSquadronTemplateMock = void 0;
const uuid_1 = require("uuid");
const its_squadron_template_model_1 = require("./its-squadron-template.model");
const its_task_1 = require("../its-task");
class ItsSquadronTemplateMock extends its_squadron_template_model_1.ItsSquadronTemplate {
    constructor(data) {
        const seedData = Object.assign({ id: (0, uuid_1.v4)(), squadronId: (0, uuid_1.v4)(), tasks: [
                new its_task_1.ItsTaskMock(),
                new its_task_1.ItsTaskMock(),
                new its_task_1.ItsTaskMock(),
                new its_task_1.ItsTaskMock(),
                new its_task_1.ItsTaskMock(),
            ], createdAt: Date.now() }, data);
        super(seedData);
    }
}
exports.ItsSquadronTemplateMock = ItsSquadronTemplateMock;
