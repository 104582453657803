"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoleType = void 0;
var RoleType;
(function (RoleType) {
    RoleType["SuperDevAdmin"] = "SuperDevAdmin";
    RoleType["SuperAdmin"] = "SuperAdmin";
    RoleType["ChiefAdmin"] = "ChiefAdmin";
    RoleType["Admin"] = "Admin";
    RoleType["AircrewMember"] = "AircrewMember";
    // TODO: Remove this role set after the migration to the new roles is completed
    RoleType["Sarm"] = "SARM";
    RoleType["SARM"] = "SARM";
    RoleType["CommandPost"] = "CommandPost";
    RoleType["CurrentOps"] = "CurrentOps";
    RoleType["StanEval"] = "StanEval";
})(RoleType = exports.RoleType || (exports.RoleType = {}));
