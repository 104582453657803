"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItsSquadronTemplate = void 0;
const base_model_1 = require("../base-model");
const its_squadron_template_schema_1 = require("./its-squadron-template.schema");
class ItsSquadronTemplate extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
        if (!this.tasks) {
            this.tasks = [];
        }
        this.createdAt = Date.now();
    }
    getDefaults() {
        return {};
    }
    getSchema() {
        return its_squadron_template_schema_1.itsSquadronTemplateSchema;
    }
}
exports.ItsSquadronTemplate = ItsSquadronTemplate;
