"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.itsSchema = void 0;
const yup = require("yup");
const its_modifications_schema_1 = require("../its-modifications/its-modifications.schema");
exports.itsSchema = yup.object().shape({
    id: yup.string().notRequired(),
    details: yup.object().shape({
        as_of: yup.number(),
        crew_position: yup.string(),
        grade: yup.string(),
        name: yup.string().required(),
        pcn: yup.string(),
        physical_due_date: yup.number(),
        physiological_due_date: yup.number(),
        prepared: yup.number(),
        records_view_due_date: yup.number(),
        rpa_physical_due_date: yup.number(),
        ssan: yup.string(),
        unit: yup.string(),
    }),
    table: yup.array().of(yup.object().shape({
        acc: yup.string(),
        accomp: yup.number(),
        data_in_phase: yup.number(),
        data_last_accomp: yup.number(),
        due_data: yup.number(),
        ovedue: yup.string(),
        pgm: yup.string(),
        qualification: yup.string(),
        rem: yup.number(),
        req: yup.number(),
        rest_code: yup.string(),
        task_id: yup.string().required(),
        task_name: yup.string().required(),
        wvd: yup.string(),
    })),
    userId: yup.string().required(),
    squadronId: yup.string().required(),
    squadronName: yup.string().required(),
    createdAt: yup.number().required(),
    updatedAt: yup.number().required(),
    userModifications: its_modifications_schema_1.itsModificationsSchema.notRequired(),
});
