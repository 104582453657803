"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItsTask = void 0;
const its_task_schema_1 = require("./its-task.schema");
const base_model_1 = require("../base-model");
class ItsTask extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
    }
    getDefaults() {
        return {};
    }
    getSchema() {
        return its_task_schema_1.itsTaskSchema;
    }
}
exports.ItsTask = ItsTask;
