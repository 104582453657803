"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.itsSquadronTemplateSchema = void 0;
const yup = require("yup");
const its_task_1 = require("../its-task");
exports.itsSquadronTemplateSchema = yup.object().shape({
    id: yup.string().notRequired(),
    squadronId: yup.string().required(),
    tasks: yup.array().of(its_task_1.itsTaskSchema).required(),
    createdAt: yup.number().default(Date.now()),
});
